<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {dateUtil} from "@/helpers/date-util";
import {swalHelper} from "@/helpers/swal-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";

import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";

/**
 * Notification For New Tags Component
 */
export default {

  components: {
    CustomCardSubtitle,

    Layout,
    PageHeader
  },

  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
        isBusy: false,

        items: []
      },

      filtering: {
        accepted: 'false'
      }

    };
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('notification-for-new-tags.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        { key: "parser", slot: true, label: "Parser" },
        { key: "tagName", label: "TAG" },
        { key: "tagPath", label: "PATH" },
        { key: "accepted", slot: true, label: "Zaakceptowany" },
        { key: "createdAt", label: this.$t('table.created-at'), formatter: dateUtil.asDateTime },
        { key: "action", slot: true, label: this.$t('table.actions') }
      ]
    },

    async loadNotifications() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/parser/tag/notification/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "page": page,
            "size": this.table.perPage,
            "accepted": this.filtering.accepted
          }
        });

        this.table.items = data.notifications
        this.table.totalRows = data.count;
        this.table.rows = data.count;
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      return this.table.items;
    },

    acceptNotification(notification) {
      swalHelper.yesOrNoCustomizable(() => {
        const obj = Object.assign({}, notification)
        obj.accepted = true

        const json = JSON.stringify(obj);
        axios.post(`/parser/tag/notification`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
        }).then(() => {
          Swal.fire("Sukces!", "TAG został zaakceptowany!", "success")
              .then(() => this.$refs.table.refresh())
        }).catch((error) => {
          errorCatcher.catchErrors(error);
        })
      }, "warning", "Czy na pewno?", `TAG zostanie zaakceptowany`, "Zaakceptuj", "Anuluj", "btn btn-success", "btn btn-danger")
    }

  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('notification-for-new-tags.title')" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Filtrowanie"/>

              <div class="col-3">
                <label>Filtruj po:</label>
                <vue-multiselect
                    v-model="filtering.accepted"
                    @select="setToFirstPageAndRefresh"
                    placeholder="Wybierz typ filtracji"
                    :options="[ 'true', 'false' ]"
                    :custom-label="value => value === 'true' ? 'Zaakceptowane' : 'Niezaakceptowane'"
                    :show-labels="false"
                />
              </div>
            </div>

            <ecat-table
                ref="table"
                :table="table"
                :fields="getFields()"
                :items="loadNotifications"
                :pagination-top="true">
              <template v-slot:parser="{ item }">
                <a :href="`/dashboard/admin/parser/create-or-edit?warehouseId=${item.warehouseId}`">{{ item.parserXmlUrl }}</a>
              </template>

              <template v-slot:accepted="{ item }">
                <span class="badge" :class="item.accepted ? 'badge-soft-success' : 'badge-soft-danger'">{{ $t(item.accepted ? 'message.yes' : 'message.no') }}</span>
              </template>

              <template v-slot:action="{ item }">
                <b-button variant="success" class="btn-sm" v-if="!item.accepted" @click="acceptNotification(item)">Zaakceptuj</b-button>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>